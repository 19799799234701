import React from 'react'

import ProjectsData from '../ProjectsData'
import SideProjectCard from './SideProjectCard'

import '../ProjectsShowcase/styles.scss'

const SideProjectsShowcase = () => {
    return (
        <section className='projects-showcase'>
            {
                ProjectsData.sideProjects.map((project) => (
                    <SideProjectCard projectImg={project.projectImg} alt={project.alt} siteLink={project.siteLink} projectTitle={project.projectTitle} projectDesc={project.projectDesc} tech1={project.tech1} tech2={project.tech2} tech3={project.tech3} tech4={project.tech4} githubLink={project.githubLink} key={project.id} />
                ))
            }
        </section>
    )
}

export default SideProjectsShowcase