import React from 'react'
import { Helmet } from 'react-helmet'

import Profile from './Profile'

import './styles.scss'

const Home = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title>Chantal St Louis Frontend Developer | Portfolio</title>
            </Helmet>

            <div className='homepage-wrapper'>
                <Profile />
            </div>
        </React.Fragment>
    )
}

export default Home
