import HTMLBadge from '../../../../images/homepage/HTML-badge-blue.png'
import CSSBadge from '../../../../images/homepage/CSS-badge-blue.png'
import JSBadge from '../../../../images/homepage/javascript-badge-blue.png'
import ReactBadge from '../../../../images/homepage/react-badge-blue.png'
import JqueryBadge from '../../../../images/homepage/JQuery-badge-blue.png'
import SCSSBadge from '../../../../images/homepage/SASS-badge-blue.png'
// import VueBadge from '../../../../images/homepage/VUE-badge-blue.png'

const SkillsData = [
    {
        techBadge: HTMLBadge,
        alt: 'HTML5 Badge',
        id: 'html'
    },
    {
        techBadge: CSSBadge,
        alt: 'CSS Badge',
        id: 'css'
    },
    {
        techBadge: JSBadge,
        alt: 'Javascript Badge',
        id: 'js'
    },
    {
        techBadge: ReactBadge,
        alt: 'React Badge',
        id: 'react'
    },
    {
        techBadge: JqueryBadge,
        alt: 'Jquery Badge',
        id: 'jquery'
    },
    {
        techBadge: SCSSBadge,
        alt: 'SCSS Badge',
        id: 'scss'
    }
    // {
    //     techBadge: VueBadge,
    //     alt: 'VUE Badge',
    //     id: 'vue'
    // },
]

export default SkillsData