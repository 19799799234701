import PelhamImage from '../../../images/portfolio/pelham-project.png'
import BrasierFreethImage from '../../../images/portfolio/brasier-freeth-project.png'
import BeautifulImage from '../../../images/portfolio/beautiful-project.png'
import CunninghamImage from '../../../images/portfolio/cunningham-project.png'
import AzwunCreativeImage from '../../../images/portfolio/azwuncreative-project.png'
import PalmeriaImage from '../../../images/portfolio/palmeria-beauty-project.png'
import IamCStlouisImage from '../../../images/portfolio/iamcstlouis-project.png'
import KrunkBurgersImage from '../../../images/portfolio/krunkburgers-project.png'
import SteakNBeerImage from '../../../images/portfolio/steak-n-beer-project.png'
import VapeViewsImage from '../../../images/portfolio/vapeviews-project.png'

const ProjectsData = {
    projects: [
        {
            projectImg: PelhamImage,
            alt: 'Pelham Design website',
            projectTitle: 'Pelham Design',
            projectDesc:
                'A responsive website for design company, specialising in interior design.',
            tech1: 'HTML',
            tech2: 'CSS',
            tech3: 'Php',
            tech4: 'Wordpress',
            siteLink: 'https://pelhamdesign.com/',
            id: 'pelham-designs'
        },
        {
            projectImg: BrasierFreethImage,
            alt: 'Brasier Freeth website',
            projectTitle: 'Brasier Freeth',
            projectDesc:
                'A responsive website for commercial property experts, Brasier Freeth.',
            tech1: 'HTML',
            tech2: 'CSS',
            tech3: 'Javascript',
            tech4: 'Jquery',
            siteLink: 'https://brasierfreeth.com/',
            id: 'brasier-freeth'
        },
        {
            projectImg: BeautifulImage,
            alt: 'Beautiful: The Carol King Musical',
            projectTitle: 'Beautiful: The Carol king Musical',
            projectDesc: 'Website for The Westend and Broadway Musical, Beautiful.',
            tech1: 'HTML',
            tech2: 'CSS',
            tech3: 'Wordpress',
            tech4: 'Php',
            siteLink: 'https://beautifulmusical.co.uk/',
            id: 'beautiful'
        },
        {
            projectImg: CunninghamImage,
            alt: 'Cunningham and Partners',
            projectTitle: 'Cunningham and Partners',
            projectDesc: 'Website for Cunningham and Partners insurance brokers.',
            tech1: 'HTML',
            tech2: 'CSS',
            tech3: 'Wordpress',
            tech4: 'Php',
            siteLink: 'https://cunninghamandpartners.com/',
            id: 'cunningham'
        },
        {
            projectImg: AzwunCreativeImage,
            alt: 'Azwun Creative - Portfolio Site',
            projectTitle: 'Azwun Creative',
            projectDesc:
                'A SPA for company specialising in creative and web development solutions.',
            tech1: 'Sketch',
            tech2: 'HTML',
            tech3: 'CSS',
            tech4: 'React.js',
            siteLink: 'https://www.azwuncreative.com/',
            id: 'azwun-creative'
        },
        {
            projectImg: PalmeriaImage,
            alt: 'Palmeria Beauty website',
            projectTitle: 'Palmeria Beauty',
            projectDesc:
                'A responsive website for a beauty company, specialising in beauty products.',
            tech1: 'Adobe XD',
            tech2: 'HTML',
            tech3: 'CSS',
            tech4: 'Javascript',
            siteLink: 'http://www.palmeriabeauty.com',
            id: 'palmeria-beauty'
            // githubLink: 'https://github.com/iamcstlouis/Palmeria-Beauty'
        },
        {
            projectImg: IamCStlouisImage,
            alt: 'iamCStlouis - Painter\'s Online Portfolio',
            projectTitle: 'Painter’s Portfolio',
            projectDesc: 'A simple multi-page online gallery for an artist.',
            tech1: 'Adobe XD',
            tech2: 'HTML',
            tech3: 'CSS',
            tech4: 'React.js',
            siteLink: 'https://iamcstlouis.com/',
            id: 'iamcstlouis'
            // githubLink: 'https://github.com/iamcstlouis/iamcstlouisPortfolio'
        }
    ],
    sideProjects: [
        {
            projectImg: KrunkBurgersImage,
            alt: 'Krunk Burgers - Portfolio Image',
            projectTitle: 'Krunk Burgers',
            projectDesc:
                'A SPA - Online menu idea for Burger restaurant based in South London, Krunk Burgers',
            tech1: 'Sketch',
            tech2: 'HTML',
            tech3: 'CSS',
            tech4: 'React.js',
            siteLink: 'https://krunkburgers.netlify.app/',
            githubLink: 'https://github.com/iamcstlouis/krunk-burgers-site',
            id: 'krunk-burgers'
        },
        {
            projectImg: SteakNBeerImage,
            alt: 'Steak \'N\' Beer Website - Portfolio Image',
            projectTitle: 'Steak ’N’ Beer',
            projectDesc:
                'A fun fictitious multi-page static website for a restaurant specialising in Steak and Beer.',
            tech1: 'Adobe XD',
            tech2: 'HTML',
            tech3: 'CSS',
            tech4: 'Javascript',
            siteLink: 'https://steaknbeer.netlify.com/',
            githubLink: 'https://github.com/iamcstlouis/SteakNBeerProject',
            id: 'steak-n-beer'
        },
        {
            projectImg: VapeViewsImage,
            alt: 'VapeViews Spacialising top quality Vape products',
            projectTitle: 'VapeViews',
            projectDesc:
                'A fun fictitious responsive website for a company presenting top rated vapes and vape products.',
            tech1: 'Adobe XD',
            tech2: 'HTML',
            tech3: 'CSS',
            tech4: 'Javascript',
            siteLink: 'https://vapeviews.netlify.com/',
            githubLink: 'https://github.com/iamcstlouis/vape-views',
            id: 'vape-views'
        }
        // {
        //   projectImg: require('./../assets/portfolio/pandora-disney-project.png'),
        //   alt: 'Pandora Disney Landing Page - Portfolio Image',
        //   projectTitle: 'Pandora X Disney',
        //   projectDesc:
        //     'For my love of Pandora & Disney: A fun landing page celebrating the Pandora Disney collaboration.',
        //   tech1: 'HTML',
        //   tech2: 'CSS',
        //   tech3: 'Javascript',
        //   tech4: 'Bootstrap',
        //   siteLink: 'https://pandoradisney.netlify.com/',
        //   githubLink:
        //     'https://github.com/iamcstlouis/pandora-x-disney-landing-page'
        // }
    ]
    // htmlEmails: [
    //   {
    //     projectImg: require('./../assets/portfolio/portfolio-cod-html-email.png'),
    //     alt: 'Call of Duty Promotional HTML Email - Portfolio Image',
    //     projectTitle: 'COD - HTML Email',
    //     projectDesc:
    //       'A simple promotional email for popular video game, Call of Duty.',
    //     tech1: 'HTML',
    //     tech2: 'CSS',
    //     siteLink: 'http://cod-promotional-email.chantalstlouis.com',
    //     githubLink: 'https://github.com/iamcstlouis/codHtmlEmail'
    //   },
    //   {
    //     projectImg: require('./../assets/portfolio/portfolio-black-ops-html-email.png'),
    //     alt: 'COD Black Ops 4 - HTML Email - Portfolio Image',
    //     projectTitle: 'COD Black Ops 4 - HTML Email',
    //     projectDesc:
    //       'A fun fictitious promotional email for popular video game, Call of Duty (Black Ops 4).',
    //     tech1: 'HTML',
    //     tech2: 'CSS',
    //     siteLink: 'http://blackops4-promotional-email.chantalstlouis.com/',
    //     githubLink: 'https://github.com/iamcstlouis/codBlackOps4HtmlEmail'
    //   }
}

export default ProjectsData