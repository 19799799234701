import React from 'react'

import './styles.scss'

const TopText = ({ title, excerpt }) => {
    return (
        <div className='top-text' data-aos="fade-in" data-aos-delay="400">
            <h1 className='top-text__hdr'>{title}</h1>
            <p className='top-text__excerpt'>{excerpt}</p>
        </div>
    )
}

export default TopText