import React from 'react'
import { Link } from 'react-router-dom'

import './styles.scss'

const BackToProfileBtn = () => {
    return (
        <div className='back-to-profile' data-aos="fade-in" data-aos-delay="400">
            <Link to='/'>
                <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                    <path d='M0 12l9-8v6h15v4h-15v6z' />
                </svg>
                <p>Back to profile</p>
            </Link>
        </div>
    )
}

export default BackToProfileBtn