import React, { useState, useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom'
import AOS from 'aos';
import 'aos/dist/aos.css';

import Header from './components/Header';
import Home from './pages/Home'
import Projects from './pages/Projects'
import NotFound from './pages/NotFound';
import SlideOutMenu from './components/SlideOutMenu';
import Footer from './components/Footer'

import './assets/scss/reset.scss'
import './assets/scss/base.scss'


function App() {
  useEffect(() => {
    AOS.init({
      duration: 1800,
      once: true,
      delay: 500
    });
  }, [])

  const location = useLocation();
  const pathname = location.pathname;
  const projectsPage = pathname === '/projects';

  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1024;

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return (
    <React.Fragment>
      <Header />

      {width >= breakpoint && <SlideOutMenu />}

      <main>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/projects' component={Projects} />
          <Route component={NotFound} />
        </Switch>
      </main>

      {
        projectsPage ? <Footer /> : null
      }
    </React.Fragment>
  );
}

export default App;
