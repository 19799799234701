import React from 'react'
import { Container } from 'react-bootstrap'

import SkillsData from './SkillsData'
import GitHubImage from '../../../images/homepage/Github-badge-blue.png'

import './styles.scss'

const SkillsShowcase = () => {
    return (
        <div className='skills-showcase'>
            <Container>
                <div className='skills-showcase__showcase d-flex justify-content-center align-items-center'>
                    {
                        SkillsData.map((skill) => (
                            <div className='skills-showcase__tech-badge' key={skill.id}>
                                <img src={skill.techBadge} alt={skill.alt} />
                            </div>
                        ))
                    }
                    <div className='skills-showcase__tech-badge skills-showcase__tech-badge--github'>
                        <a href='https://github.com/iamcstlouis' rel='noopener noreferrer' target='_blank'>
                            <img src={GitHubImage} alt='Github Badge' />
                        </a>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default SkillsShowcase