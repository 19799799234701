import React from 'react'

import ProjectsData from '../ProjectsData'
import ProjectCard from './ProjectCard'

import './styles.scss'

const ProjectsShowcase = () => {
    return (
        <section className='projects-showcase'>
            {
                ProjectsData.projects.map((project) => (
                    <ProjectCard projectImg={project.projectImg} alt={project.alt} siteLink={project.siteLink} projectTitle={project.projectTitle} projectDesc={project.projectDesc} tech1={project.tech1} tech2={project.tech2} tech3={project.tech3} tech4={project.tech4} key={project.id} />
                ))
            }
        </section>
    )
}

export default ProjectsShowcase