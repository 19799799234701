import React from 'react'
import { Link } from 'react-router-dom'

import './styles.scss'

const ProjectsLink = ({ aosAnimationStyle, aosDelay, btnLink, btnText }) => {
    return (
        <div className='projects-link' data-aos={aosAnimationStyle} data-delay={aosDelay}>
            <Link to={btnLink} className='projects-link__link'>{btnText}</Link>
        </div>
    )
}

export default ProjectsLink