import React from 'react'
import { Helmet } from 'react-helmet'
import { Container } from 'react-bootstrap'

import TopText from './TopText'
import ProjectsShowcase from './ProjectsShowcase'
import SideProjectsShowcase from './SideProjectsShowcase'

const Projects = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title>Chantal St Louis Frontend Developer | Projects</title>
            </Helmet>
s
            <div className='projects'>
                <Container>
                    <TopText title={'Projects'} excerpt={'Just a few that I\'ve worked on...'} />
                    <ProjectsShowcase />
                    <TopText title={'Side Projects'} excerpt={'Projects I\'ve put together in my spare time...'} />
                    <SideProjectsShowcase />
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Projects