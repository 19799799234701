import React from 'react'
import { Link } from 'react-router-dom'

import './styles.scss'

const Footer = () => {
    const getYear = () => {
        return new Date().getFullYear();
    }

    return (
        <footer className='footer'>
            <p className='footer__copyright'>
                Designed and coded by
                <Link to='/'> Chantal St Louis</Link> &copy;{getYear()}
            </p>
        </footer>
    )
}

export default Footer