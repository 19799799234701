import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import BackToProfileBtn from './BackToProfileBtn'

import './styles.scss'

const Header = () => {

    const location = useLocation();
    const pathname = location.pathname;
    const projectsPage = pathname === '/projects';

    const [mobileMenuOpen, setMenuState] = useState(false);

    return (
        <header className={projectsPage ? 'portfolio' : ''}>
            <Container>
                {
                    projectsPage ? <BackToProfileBtn /> : null
                }

                <div className='hamburger-btn'>
                    <div id='hamburgerToggler' className={`hamburger-toggler ${!mobileMenuOpen ? '' : 'open'}`}
                        onClick={() => setMenuState(!mobileMenuOpen)}
                    >
                        <div className={`bar half start ${!mobileMenuOpen ? '' : 'open'}`}></div>
                        <div className={`bar ${!mobileMenuOpen ? '' : 'open'}`}></div>
                        <div className={`bar half end ${!mobileMenuOpen ? '' : 'open'}`}></div>
                    </div>
                </div>

                <div id='mobile-menu' className={`mobile-menu ${!mobileMenuOpen ? '' : 'open'}`}>
                    <div className='mobile-menu__logo' onClick={() => setMenuState(!mobileMenuOpen)}>
                        <Link to='/'>
                            <svg width='107.666' height='83.946' viewBox='0 0 107.666 83.946'>
                                <g transform='translate(-263.699 -394.6)'>
                                    <path
                                        d='M333.063,417.936l-26.715.322a17.592,17.592,0,0,0-12.875,5.15,18.219,18.219,0,0,0-4.989,13.2,17.466,17.466,0,0,0,4.989,12.875,17.117,17.117,0,0,0,12.553,5.15h18.347v23.336H306.509c-12.231,0-22.37-4.023-30.578-11.909S263.7,448.352,263.7,436.6a39.751,39.751,0,0,1,12.553-29.773q12.553-12.07,30.9-12.07l43.453-.161h0C350.283,394.922,334.833,402,333.063,417.936Z'
                                        transform='translate(0 0)'
                                        fill='#101010'
                                    />
                                    <path
                                        d='M312.517,454.507c-.483,0-8.851.161-9.817.161v23.5c29.129,1.931,36.371-7.564,36.371-7.564a27.119,27.119,0,0,0,8.53-20.117c0-5.472-2.092-9.656-5.472-15.45l-1.931-3.219c-1.448-2.575-2.092-4.506-2.092-6.276a6.55,6.55,0,0,1,1.931-4.828,6.945,6.945,0,0,1,4.506-2.092V395.283a35.178,35.178,0,0,0-5.794-.483,26.368,26.368,0,0,0-19.473,8.047,27.5,27.5,0,0,0-8.208,19.8,37.77,37.77,0,0,0,5.472,19.151c2.253,4.023,3.38,6.6,3.38,7.886C319.92,449.84,320.242,454.185,312.517,454.507Z'
                                        transform='translate(23.765 0.122)'
                                        fill='#101010'
                                    />
                                </g>
                            </svg>
                        </Link>
                    </div>

                    <div className='mobile-menu__profile-info'>
                        <p className='mobile-menu__profile-info--name'>Chantal St Louis</p>
                        <p className='mobile-menu__profile-info--occupation'>Front-end Developer</p>
                    </div>

                    <div className='mobile-menu__socials'>
                        <a href='https://github.com/iamcstlouis' rel='noopener noreferrer' target='_blank'>
                            <svg width='24' height='24' viewBox='0 0 24 24' className='mobile-menu__socials--github'>
                                <path
                                    d='M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z'
                                />
                            </svg>
                        </a>

                        <a href='https://www.linkedin.com/in/chantal-st-louis-a117a634/' rel='noopener noreferrer' target='_blank'>
                            <svg width='24' height='24' viewBox='0 0 24 24' className='mobile-menu__socials--linkedIn'>
                                <path
                                    d='M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z'
                                />
                            </svg>
                        </a>

                        <a href='https://www.instagram.com/chantalcodes/' rel='noopener noreferrer' target='_blank'>
                            <i className='fab fa-instagram'></i>
                        </a>
                    </div>
                </div>
            </Container>
        </header>
    )
}

export default Header