import React from 'react'

import SiteAccentBtn from '../../components/SiteAccentBtn'

import './styles.scss'

const NotFound = () => {
    return (
        <div className='not-found'>
            <div className='not-found__msg' data-aos='fade-in'>
                <span className='not-found__msg--404-graphic'>404</span>
                <h1 className='not-found__msg--hdr' data-aos='fade-up' data-aos-delay='200'>
                    <span className='not-found__msg--accent'>Oops!</span> It appears you're lost.
                </h1>
                <p className='not-found__msg--sub' data-aos='fade-up' data-aos-delay='400'>This page does not exist.</p>
                <SiteAccentBtn aosAnimationStyle={'fade-up'} aosDelay={'600'} btnLink={'/'} btnText={'Back To My Profile'} />
            </div>
        </div>
    )
}

export default NotFound